const initialState = {
    events: []
};
const reducer = (state=initialState, action)=>{
    if(action.type === 'eventList') {
        return {
            ...state,
            events: [ ...state.events, ...action.payload]
        }
    } else if(action.type === 'updateEvent') {

    } else {
        return state;
    }
}
export default reducer;