import i18n from "i18next";
import { initReactI18next } from 'react-i18next'

import translationEN from "./locales/en/translationEN.json";
import translationAR from "./locales/ar/translationAR.json";
import translationMA from "./locales/ma/translationMA.json";

//THE TRANSLATIONS

const resources = {
    en: {
        translation: translationEN
    },
    ar: {
        translation: translationAR
    },
    ma: {
        translation: translationMA
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: "en",
    /* debugger For Development environment */
    debug: true,
    ns: ["translation"],
    defaultNS: "translation",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
});

export default i18n;