import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import i18n from './i18n'
import { I18nextProvider } from 'react-i18next';
import ReactDOM from 'react-dom';
import firebase from 'firebase';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './state/store';

const firebaseConfig = {
    apiKey: "AIzaSyCtP4F83G84A9zU3RWB6L2zPlZ1GK3UU_w",
    authDomain: "clubapp-anaswara.firebaseapp.com",
    projectId: "clubapp-anaswara",
    storageBucket: "clubapp-anaswara.appspot.com",
    messagingSenderId: "516459055587",
    appId: "1:516459055587:web:622f6a5673f52e9998b8d6",
    measurementId: "G-SWXJ59G27H"
  };
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
// Disable deprecated features
db.settings({
    timestampsInSnapshots: true
});

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <App />
        </Provider>
        </I18nextProvider>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
